var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "manager-container" },
    [
      _vm.pageVisible
        ? [
            _c(
              "div",
              { staticClass: "left" },
              [
                _vm.hasSysAdminPermission
                  ? _c("base-block-title", {
                      attrs: {
                        title: _vm.$t("company"),
                        "sub-title": _vm.companyList.length,
                      },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "content" },
                  _vm._l(
                    _vm.hasSysAdminPermission
                      ? _vm.companyList
                      : [this.currentCompany],
                    function (company) {
                      return _c(
                        "div",
                        {
                          key: company.id,
                          staticClass: "company-name",
                          class: {
                            active: company.id === _vm.currentCompany.id,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleCompanyClick(company)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              class: {
                                active:
                                  company.id === _vm.currentCompany.id &&
                                  !_vm.currentTeam.id,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleCompanyTitleClick(company)
                                },
                              },
                            },
                            [
                              _c("em", { staticClass: "el-icon-suitcase" }),
                              _vm._v(" " + _vm._s(company.companyName) + " "),
                            ]
                          ),
                          _vm.currentCompany.id === company.id &&
                          !_vm.teamLoading
                            ? _vm._l(_vm.teams || [], function (team) {
                                return _c(
                                  "div",
                                  {
                                    key: team.id,
                                    staticClass: "team-name",
                                    class: {
                                      active: team.id === _vm.currentTeam.id,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.onTeamClick(team)
                                      },
                                    },
                                  },
                                  [
                                    _c("em", {
                                      staticClass: "el-icon-files",
                                      staticStyle: { "margin-left": "10px" },
                                    }),
                                    _vm._v(" " + _vm._s(team.name) + " "),
                                  ]
                                )
                              })
                            : _vm._e(),
                          _vm.currentCompany.id === company.id &&
                          _vm.hasPermission(_vm.$permissionFunctionCode.ADD)
                            ? _c(
                                "el-dropdown",
                                { on: { command: _vm.handleTeam } },
                                [
                                  _c("em", {
                                    staticClass: "el-icon-more icon-color",
                                  }),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "add" } },
                                        [_vm._v(_vm._s(_vm.$t("addTeam")))]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: {
                                            disabled:
                                              !_vm.currentTeam.id ||
                                              _vm.currentTeam.type === 0,
                                            command: "edit",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("editTeam")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          style: {
                                            color:
                                              !_vm.currentTeam.id ||
                                              _vm.currentTeam.type === 0
                                                ? "#bbb"
                                                : "#F56C6C",
                                          },
                                          attrs: {
                                            disabled:
                                              !_vm.currentTeam.id ||
                                              _vm.currentTeam.type === 0,
                                            command: "delete",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("deleteTeam")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    }
                  ),
                  0
                ),
              ],
              1
            ),
            _c("div", { staticClass: "right" }, [
              _vm.hasSysAdminPermission
                ? _c(
                    "div",
                    { staticClass: "title-button" },
                    [
                      _c("base-block-title", {
                        attrs: {
                          title: _vm.$t("user"),
                          "sub-title":
                            _vm.currentTeam.name ||
                            _vm.currentCompany.companyName,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "user-container" },
                [
                  !_vm.users.length ? _c("base-no-data") : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "users" },
                    _vm._l(_vm.users, function (user) {
                      return _c("user-card", {
                        key: user.id,
                        attrs: {
                          user: user,
                          teams: _vm.teams || [],
                          company: _vm.currentCompany,
                        },
                        on: { "handle-success": _vm.onUserHandleSuccess },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.changePasswordVisible, width: "40%" },
          on: {
            "update:visible": function ($event) {
              _vm.changePasswordVisible = $event
            },
          },
        },
        [
          _c("base-title", {
            attrs: { slot: "title", text: _vm.$t("resetPassword") },
            slot: "title",
          }),
          _c(
            "div",
            { staticStyle: { padding: "20px" } },
            [
              _c(
                "el-form",
                {
                  ref: "pswForm",
                  attrs: {
                    model: _vm.pswFormData,
                    rules: _vm.rules,
                    "label-width": _vm.$i18n.locale === "cn" ? "80px" : "160px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("username") } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          value: _vm.currentUser.name,
                          disabled: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("newPsw"), prop: "password" } },
                    [
                      _c("el-input", {
                        attrs: { type: "password" },
                        model: {
                          value: _vm.pswFormData.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.pswFormData, "password", $$v)
                          },
                          expression: "pswFormData.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("confirmPaw"),
                        prop: "confirmPaw",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "password" },
                        model: {
                          value: _vm.pswFormData.confirmPaw,
                          callback: function ($$v) {
                            _vm.$set(_vm.pswFormData, "confirmPaw", $$v)
                          },
                          expression: "pswFormData.confirmPaw",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.changePasswordVisible = false
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submitResetPassword },
                },
                [_vm._v(" " + _vm._s(_vm.$t("submit")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.queryVisible, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.queryVisible = $event
            },
            closed: function ($event) {
              _vm.queryFormData.name = ""
            },
          },
        },
        [
          _c("base-title", {
            attrs: { slot: "title", text: _vm.$t("query") },
            slot: "title",
          }),
          _c(
            "div",
            { staticStyle: { padding: "0 30px" } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("fullname") },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.submitQuery.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.queryFormData.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.queryFormData,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "queryFormData.name",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.queryVisible = false
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submitQuery },
                },
                [_vm._v(" " + _vm._s(_vm.$t("submit")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }